body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Section__container___3YYTG {
  background: url(./logo-white-bg.png) center 10px no-repeat;
  background-size: 200px auto;
  padding-top: 100px;
}

/* sign in button */
.Button__button___vS7Mv {
  background-color: rgb(0, 200, 83) !important;
}

/* links */
.Anchor__a___1_Iz8 {
  color: rgb(0, 200, 83) !important;
}
